import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static values = {show: Boolean}
    static targets = ['input', 'icon']

    show(event) {
        event.preventDefault();
        if (this.showValue) {
            this.showValue = false
            this.inputTarget.setAttribute('type' , 'password')
            this.iconTarget.classList.add('fa-eye-slash')
            this.iconTarget.classList.remove('fa-eye')
        } else {
            this.showValue = true
            this.inputTarget.setAttribute('type', 'text')
            this.iconTarget.classList.remove('fa-eye-slash')
            this.iconTarget.classList.add('fa-eye')
        }
    }
}