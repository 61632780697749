import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
    }

    show(event) {
        event.preventDefault()
        event.stopPropagation()
        const show_attr = event.params.attr
        console.log(show_attr)
        console.log('td[data-show="' + show_attr + '"]')
        let elements = $('*[data-show="' + show_attr + '"]')
        console.log(elements)
        const ico = $(event.currentTarget).find('i')
        if (ico.hasClass('fa-plus')) {
            ico.removeClass('fa-plus').addClass('fa-minus')
            elements.removeClass('d-none')
        } else {
            ico.removeClass('fa-minus').addClass('fa-plus')
            elements.addClass('d-none')
        }
    }
}