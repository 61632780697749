import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    hamburgerController = null

    click(event) {
        if (this.hamburgerController === null) {
            this.hamburgerController = this.application
                .getControllerForElementAndIdentifier(
                    document.getElementsByClassName("hamburger")[0],
                    'header--hamburger'
                )
        }
        if (this.hamburgerController !== null) {
            this.hamburgerController.click_sidebar(event)
        }
    }

}