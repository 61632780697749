import {Controller} from "@hotwired/stimulus";

export default class extends Controller {

    connect() {
        let tabTrigger = new bootstrap.Tab(this.element)
        this.element.addEventListener('click', function (event) {
            event.preventDefault()
            tabTrigger.show()
        })
    }
}