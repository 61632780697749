import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    copyToBuffer(event) {
        const form = $(event.currentTarget).closest('form');
        let text = "";
        $(form).find('.btn-link').each(function () {
            if ($(this).hasClass('disabled') === false) {
                text += $(this).attr('href') + " \r\n";
            }
        });
        // console.log(text)
        let $temp = $("<textarea>");
        $("body").append($temp);
        $temp.html(text).select();
        navigator.clipboard.writeText($temp.val());
        $temp.remove();
    }

    selectUrlValues(event) {
        const form = $(event.currentTarget).closest('form');
        $(form).find('.btn-link').addClass('disabled');
        const btn_copy_to_buffer = $(form).find('.btn-copy_to_buffer');
        btn_copy_to_buffer.addClass('disabled')
        $.ajax({
            url: $(form).attr('action'),
            data: form.serialize(),
            type: 'POST',
            cache: true,
            success: function (dat) {
                if (dat.error === undefined) {
                    if (dat['link1'] !== undefined) {
                        $(form).find('#link1')
                            .attr('href', dat['link1'])
                            .attr('title', dat['link1'])
                            .removeClass('disabled');
                        btn_copy_to_buffer.removeClass('disabled');
                    }
                    if (dat['link2'] !== undefined) {
                        $(form).find('#link2')
                            .attr('href', dat['link2'])
                            .attr('title', dat['link2'])
                            .removeClass('disabled');
                        btn_copy_to_buffer.removeClass('disabled');
                    }
                    if (dat['link3'] !== undefined) {
                        $(form).find('#link3')
                            .attr('href', dat['link3'])
                            .attr('title', dat['link3'])
                            .removeClass('disabled');
                        btn_copy_to_buffer.removeClass('disabled');
                    }
                }
            }
        })
    }

    saveSettings(event) {
        const form = $(event.currentTarget).closest('form');
        const result = $(form).find('#save-result');
        result.html("Save...");
        const dt = form.serialize();
        $.ajax({
            url: $(form).attr('action'),
            data: dt,
            type: 'POST',
            cache: false,
            success: function (dat) {
                if (dat.error !== undefined) {
                    result.html('Save - Error')
                } else {
                    result.html('Save - ok')
                    if (dat['link'] !== undefined) {
                        $(form).find('#link')
                            .attr('href', dat['link'])
                            .attr('title', dat['link'])
                            .removeClass('disabled');
                        const btn_copy_to_buffer = $(form).find('.btn-copy_to_buffer');
                        btn_copy_to_buffer.removeClass('disabled');
                    }
                }
            }
        }).always(function () {
        });
    }

}