import {Controller} from "@hotwired/stimulus"
export default class extends Controller {

    static values = {method: String, url: String, type: {type: Number, default: -1}}
    static targets = ['modalWraper']

    show() {
        event.preventDefault()
        event.stopPropagation()
        let url = new URL(window.location.origin + this.urlValue)
        if (this.typeValue > 0) {
            url.searchParams.append("type", this.typeValue)
        }
        //console.log(url.href)
        fetch(url.href, {
            method: this.methodValue,
        }).then(response => {
            if (response.ok) {
                return response.json()
            }
            return Promise.reject(response)
        }).then(data => {
            this.dispatch('show', {detail: data.edit});
        }).catch(error => {
            console.warn(error)
        })
    }
}