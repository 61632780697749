import {Controller} from "@hotwired/stimulus";

export default class extends Controller {

    show(event) {
        event.preventDefault()
        let id = undefined
        let hide = undefined

        const class_lev1 = event.params['l1']
        const class_lev2 = event.params['l2']
        const class_lev3 = event.params['l3']

        if (class_lev3 !== undefined) {
            id = 'tr_l3_' + class_lev3
            hide = 'tr_h3_' + class_lev3
        }
        if (class_lev2 !== undefined) {
            id = 'tr_l2_' + class_lev2
            hide = 'tr_h2_' + class_lev2
        }
        if (class_lev1 !== undefined) {
            id = 'tr_l1_' + class_lev1
            hide = 'tr_h1_' + class_lev1
        }

        if (id !== undefined) {
            if (event.currentTarget.innerHTML === '+') {
                $('.' + id).removeClass('d-none');
                event.currentTarget.innerHTML = '-';
            } else {
                $('.' + hide).each(function () {
                    $(this).addClass('d-none');
                    $(this).find('button').html('+')
                    console.log($(this).find('button').html())
                })

                $('.' + id).addClass('d-none')
                event.currentTarget.innerHTML = '+';
            }
        }

    }
}