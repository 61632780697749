import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static values = {rowField: String}

    click(event) {
        event.preventDefault()
        event.stopPropagation()
        $(this.element).blur()
        const row = $(this.element).closest(this.rowFieldValue);
        row.find('input').val('');
        row.remove();
    }
}