import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static values = { url: String}

    change_profile (Event) {
        fetch(this.urlValue, {
            method: 'POST',
        }).then(
            response => {
                if (response.ok) {
                    return response.json()
                }
                return Promise.reject(response)
            }
        ).then(data => {
            $(this.element).html(data.html)
        }).catch(error => {
            console.warn(error)
        })
    }
}