import {Controller} from "@hotwired/stimulus"
import $ from "jquery";
import {useWindowResize} from 'stimulus-use'

export default class extends Controller {
    static values = {isLanding: Boolean}

    width_step = 1200
    width_mobile = 760
    open = false
    manual_closed = false
    mobile = false
    cookie_menu_hide = false
    cookie_name = 'menu-hide'
    cookie_time = 365

    connect() {
        useWindowResize(this)
        if (this.isLandingValue) {

        } else {
            this.getCookie(this.cookie_name)
            if (this.cookie_menu_hide) {
                this.menu_close()
            } else {
                if (window.innerWidth < this.width_step) {
                    this.menu_close()
                } else {
                    this.menu_open()
                }
            }
        }
        //console.log(this)
    }

    // noinspection JSUnusedGlobalSymbols
    windowResize({width, height, event}) {
        //this.widthTarget.textContent = width
        if (width <= this.width_mobile) {
            if (!this.mobile) {
                this.mobile = true
                if (this.isLandingValue) {
                    this.element.classList.remove('d-none')
                } else {
                    this.menu_open()
                    this.btn_close()
                }
            }
        } else {
            if (this.isLandingValue) {
                if (this.mobile) {
                    this.mobile = false
                    this.element.classList.add('d-none')
                    document.removeEventListener('click', this.clickHandler)
                }
            } else {
                if (this.mobile) {
                    this.mobile = false
                    document.removeEventListener('click', this.clickHandler)
                    if (this.cookie_menu_hide)
                        this.menu_close()
                }
                if (!this.cookie_menu_hide) {
                    if (!this.manual_closed) {
                        if (width < this.width_step) {
                            this.menu_close()
                        } else {
                            this.menu_open()
                        }
                    }
                }
            }
        }
    }

    menu_close() {
        this.open = false
        $('.app-container').addClass('closed-sidebar-mobile closed-sidebar');
        this.setCookie(this.cookie_name, 'true', this.cookie_time)
        this.btn_close()
    }

    menu_open() {
        this.open = true
        $('.app-container').removeClass('closed-sidebar-mobile closed-sidebar');
        this.eraseCookie(this.cookie_name)
        this.btn_open()
    }

    menu_mobile_open() {
        $('.app-main').addClass('sidebar-mobile-open')
        this.btn_open()
        this.clickHandler = this.hideMobileMenuOnClickOutside.bind(this);
        document.addEventListener('click', this.clickHandler)
    }

    menu_mobile_close() {
        $('.app-main').removeClass('sidebar-mobile-open')
        this.btn_close()
        document.removeEventListener('click', this.clickHandler)
    }

    btn_close() {
        $(this.element).removeClass('is-active');
        this.open = false
    }

    btn_open() {
        $(this.element).addClass('is-active');
        this.open = true
    }

    click_sidebar() {
        if (!this.mobile && !this.open) {
            event.preventDefault()
            event.stopPropagation()
            this.menu_open()
        }
    }

    landing_menu_open() {
        document.querySelector('.landing-menu-mobile__popup').classList.add('show')
        this.btn_open()
        this.clickHandler = this.hideMobileMenuOnClickOutside.bind(this);
        document.addEventListener('click', this.clickHandler)
    }

    landing_menu_close() {
        document.querySelector('.landing-menu-mobile__popup').classList.remove('show')
        this.btn_close()
        document.removeEventListener('click', this.clickHandler)
    }

    click(event) {
        event.preventDefault()
        event.stopPropagation();
        if (this.isLandingValue) {
            if (this.open) {
                this.landing_menu_close()
            } else {
                this.landing_menu_open()
            }
        } else {
            if (this.mobile) {
                if (this.open) {
                    this.menu_mobile_close()
                } else {
                    this.menu_mobile_open()
                }
            } else {
                if (this.open) {
                    this.menu_close()
                    this.manual_closed = true
                } else {
                    this.menu_open()
                    this.manual_closed = false
                }
            }
        }
    }

    hideMobileMenuOnClickOutside() {
        if (this.isLandingValue) {
            if (event.target.closest('.landing-menu-mobile__popup') === null) {
                event.preventDefault()
                event.stopPropagation();
                this.landing_menu_close()
            }
        } else {
            if (event.target.closest('.app-sidebar') === null) {
                event.preventDefault()
                event.stopPropagation();
                this.menu_mobile_close()
            }
        }
    }

    setCookie(name, value, days) {
        let expires = "";
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
        this.cookie_menu_hide = true
    }

    getCookie(name) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([.$?*|{}()\[\]\\\/+^])/g, '\\$1') + "=([^;]*)"
        ));
        const cookie = matches ? decodeURIComponent(matches[1]) : false
        this.cookie_menu_hide = cookie === 'true';
    }

    eraseCookie(name) {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        this.cookie_menu_hide = false
    }
}