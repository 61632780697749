const GosSocket = require('../../vendor/gos/web-socket-bundle/public/js/websocket.min.js')
import toastr from 'toastr';
import * as notifications from './../landing/js/notifications'

toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": true,
    "progressBar": true,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "3000",
    "extendedTimeOut": "5000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut",
};

// noinspection JSUnresolvedVariable
class MySocket {

    max_id_message = 0
    _webSocketSession = null
    _periodic_requests = false

    constructor(periodic_messages = false) {
        this._webSocket = null;
        this._sessionRefreshStatus = false
        this._webSocketSession = null;
        this._webSocketParams = {retryDelay: 10000, maxRetries: 10000}
        this._webSocketURL = ws_client_url;
        this._periodic_requests = periodic_messages;
    }

    connect(uri = this._webSocketURL) {
        let _this = this;

        if (_this._webSocket !== null) {
            delete _this._webSocket
        }

        _this._webSocket = new GosSocket.connect(uri, _this._webSocketParams);

        _this._webSocket.on('socket/connect', function (session) {

            // To set a class property, need to use the `_this` var we declared before
            _this._webSocketSession = session;

            if (ws_admin) {
                console.log('Successfully connected to ' + _this._webSocketURL);
            }

            session.subscribe('ws/topic/service', function (uri, payload) {
                if (payload.command !== undefined) {
                    switch (payload.command) {
                        case 'connectOk':
                            _this._sessionRefreshStatus = false
                            _this.connectServices(_this)
                            break;
                        case 'refreshSession':
                            if (!_this._sessionRefreshStatus) {
                                _this.refreshSession(_this)
                            } else {
                                setTimeout(_this.refreshSession.bind(null, _this), 30000)
                            }
                            break
                        default:
                    }
                }
            })
        });

        // Or, you can use arrow functions to have `this` scoped correctly
        this._webSocket.on('socket/disconnect', (error) => {
            if (ws_admin) {
                console.log('Disconnected for ' + error.reason + ' with code ' + error.code);
            }
            _this.max_id_message = 0
            _this._webSocketSession = null
            if (error.code === _this._webSocket.autobahn.CONNECTION_UNREACHABLE
                /*|| error.code === this._webSocket._autobahn.CONNECTION_CLOSED*/) {
                setTimeout(_this.reconnect.bind(null, _this), 10000)
                //setTimeout(_this.connect, 10000)
            }
        })
    }

    connectServices(_this) {
        _this._webSocketSession.subscribe('ws/topic/toasts', function (uri, payload) {
            if (ws_admin) {
                console.log('Received toast', payload)
            }
            if (payload.toast !== undefined) {
                _this.confirm_toast(payload.toast)
            }
        })

        notifications.deleteAllMessages()

        _this._webSocketSession.subscribe('ws/topic/messages', function (uri, payload) {
            if (payload.messages !== undefined) {
                if (ws_admin) {
                    if (payload.messages.length > 0) {
                        console.log('Received message', payload)
                    }
                }
                _this.topic_messages(payload.messages, _this)
            }
            if (payload.delete_messages !== undefined) {
                if (ws_admin) {
                    if (payload.delete_messages.length > 0) {
                        console.log('Delete message', payload)
                    }
                }
                _this.delete_messages(payload.delete_messages)
            }
            if (_this._periodic_requests) {
                setTimeout(_this.periodicCheckMessages.bind(null, _this), 10000)
            }
        })
    }

    reconnect(_this) {
        _this.connect()
    }

    refreshSession(_this) {
        _this._sessionRefreshStatus = true
        $.ajax({
            url: ws_session_refresh,
            type: 'get',
            processData: false,
            contentType: false,
            success: function (data) {
                try {
                    if (data.status !== undefined && data.status) {
                        console.log('reconnect')
                        _this._webSocket.session.close()
                        _this.connect()
                    } else {
                        window.location.reload();
                    }
                } catch (e) {
                    window.location.reload();
                }
            },
            fail: function (jqXHR, textStatus) {
                console.log('Error: session refresh: ' + textStatus)
            }
        })
    }

    periodicCheckMessages(_this) {
        if (_this._webSocketSession != null) {
            _this._webSocketSession.publish('ws/topic/messages', {get_messages: _this.max_id_message})
        }
    }

    topic_messages(messages, _this) {
        messages.forEach(function (message) {
            const type = message['type']
            const txt = message['message']
            const url = message['url']
            const id = message['id']
            const token = message['token']
            notifications.addMessage(type, txt, url, id, token)
            if (id > _this.max_id_message) {
                _this.max_id_message = id
            }
        })
        notifications.setPushCountNum()
    }

    delete_messages(messages) {
        messages.forEach(function (id) {
            notifications.deleteMessage(id)
        })
        notifications.setPushCountNum()
    }

    confirm_messages(token, close) {
        this._webSocketSession.publish('ws/topic/messages', {token: token, close: close})
    }

    confirm_toast(msg) {
        try {
            const toast = JSON.parse(msg)
            this._webSocketSession.publish('ws/topic/toasts', {token: toast.token})
            // Show Toast
            toastr.options.timeOut = toast.timeOut
            toastr.options.extendedTimeOut = toast.extendedTimeOut
            toastr[toast.type](toast.message, toast.header)
        } catch (e) {
            if (ws_admin) {
                console.log(e)
            }
            return false
        }
    }
}

let my_ws = null

function confirmNotification(token, close = false) {
    if (my_ws != null) {
        my_ws.confirm_messages(token, close)
    }
}

function startNotification() {
    if (my_ws === null) {
        my_ws = new MySocket()
        notifications.initBtnMessages()
        if (ws_enable > 0) {
            my_ws.connect()
        }
    }
}

export {confirmNotification, startNotification}
