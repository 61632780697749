// header push-messages

import PerfectScrollbar from 'perfect-scrollbar';
import * as websocket from './../../front/websocket'

// push number
function setPushCountNum() {
    const number = document.querySelector('.push-number')
    const noMessages = document.querySelector('.push-messages-no-messages')
    let messagesCount = document.querySelectorAll('.ws-messages').length
    if (messagesCount > 0) {
        number.classList.add('show')
        noMessages.classList.remove('show')
    } else {
        number.classList.remove('show')
        noMessages.classList.add('show')
        // pushMenu.innerHTML = 'No messages'
    }
    number.innerHTML = messagesCount > 99 ? '99+' : messagesCount

}

function initBtnMessages() {
    // delete message from list
    const closeButtons = document.querySelectorAll('.push-message-close')
    closeButtons.forEach(closeButton => {
        closeButton.addEventListener('click', removePushMessage)
    })
    setPushCountNum()
    new PerfectScrollbar('.push-messages-pupup', {
        wheelSpeed: 2,
        wheelPropagation: false,
        minScrollbarLength: 20
    });
}

function removePushMessage(event) {
    event.stopPropagation()
    event.preventDefault()
    const token = event.target.dataset.token
    websocket.confirmNotification(token, true)
    const messageID = event.target.dataset.id
    document.querySelector(`#${messageID}`).remove()
    setPushCountNum()
}

function clickMessage(event) {
    event.preventDefault()
    const messageBlock = event.target.closest('a')
    const token = messageBlock.dataset.token
    const url = messageBlock.getAttribute('href')
    const messageID = messageBlock.getAttribute('id')
    document.getElementById(messageID).remove()
    setPushCountNum()
    websocket.confirmNotification(token, false)
    if (url !== "#") location.href = url;
}

function deleteMessage(messageID) {
    const message = document.querySelector(`#MessageId_${messageID}`)
    if (message !== null) {
        message.remove()
    }
}

function deleteAllMessages() {
    const elements = document.querySelectorAll(`.ws-messages`)
    if (elements !== null) {
        elements.forEach(function (element) {
            element.remove()
        })
    }
}

function addMessage(type, message, url, id, token) {

    console.log({type, message, url, id, token})
    const blk_message = document.getElementById('message_blank').cloneNode(true)
    const closeMessageBtn = blk_message.querySelector('.push-message-close')

    blk_message.id = 'MessageId_' + id
    if (url) blk_message.href = url
    blk_message.dataset.token = token || 'error'
    blk_message.classList.replace('d-none', 'ws-messages')
    blk_message.querySelector('.push-message').classList.add(type)
    blk_message.querySelector('.push-message-text').innerText = message
    closeMessageBtn.dataset.id = 'MessageId_' + id
    closeMessageBtn.dataset.token = token
    closeMessageBtn.addEventListener('click', removePushMessage)
    blk_message.addEventListener('click', clickMessage)

    document.querySelector('.push-messages-pupup').prepend(blk_message)
}

export {setPushCountNum, addMessage, deleteMessage, deleteAllMessages, initBtnMessages}
