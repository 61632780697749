import {Controller} from "@hotwired/stimulus";

export default class extends Controller {

    static values = {'runMessage': String, 'formId': String, 'url': String}


    send(event) {
        event.preventDefault()
        event.stopPropagation()

    }
}