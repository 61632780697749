// noinspection JSUnresolvedReference

import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static values = {'runMessage': String, 'action': String}
    static outlets = ['form--form-runs', 'form--form']
    static targets = ['year', 'week']


    connect() {
        if (this.hasYearTarget) {
            $(this.yearTarget).on('select2:select', function () {
                let event = new Event('change', {bubbles: true}) // fire a native event
                this.dispatchEvent(event);

            });
        }
        if (this.hasWeekTarget) {
            $(this.weekTarget).on('select2:select', function () {
                let event = new Event('change', {bubbles: true}) // fire a native event
                this.dispatchEvent(event);
            });
        }
    }

    select_year(event) {
        event.preventDefault()
        event.stopPropagation()
        //
        this.htmlFieldLock()
        console.log(event.currentTarget.value)
        let data = new FormData()
        data.append('year', event.currentTarget.value)
        fetch(this.actionValue, {
            method: 'POST',
            body: data,
        }).then(response => {
            if (response.ok) {
                return response.json()
            }
            return Promise.reject(response)
        }).then(data => {
            if (data.error !== undefined) {
                if (this.hasFormFormRunsOutlet) {
                    this.formFormResultOutlet.error(data.error)
                }
            }
            if (data.week !== undefined) {
                if (this.hasWeekTarget) {
                    let options = [];
                    $.each(data.week, function (key, value) {
                        options.push({
                            text: key,
                            id: value
                        });
                    })
                    $(this.weekTarget).empty().select2({
                        data: options,
                        theme: "bootstrap-5",
                    });
                }
            }
        }).catch(error => {
            this.setError(error, 'send form')
        }).then(() => {
            this.htmlFieldUnLock()
        })
    }

    setError(error, name = "") {
        if (this.hasFormFormRunsOutlet) {
            this.formFormRunsOutlet.error("Error \"" + name + "\": " + error.status)
        }
    }

    htmlFieldLock() {
        if (this.hasFormFormRunsOutlet) {
            this.formFormRunsOutlet.show(this.runMessageValue)
        }
        Array.from(this.formFormOutlets).forEach(controller=> {
            Array.from(controller.formTarget).forEach(element => {
                element.setAttribute("disabled", "disabled")
            })
        })
    }

    htmlFieldUnLock() {
        if (this.hasFormFormRunsOutlet) {
            this.formFormRunsOutlet.hide()
        }
        Array.from(this.formFormOutlets).forEach(controller=> {
            Array.from(controller.formTarget).forEach(element => {
                element.removeAttribute("disabled")
            })
        })
    }
}