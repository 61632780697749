import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    static values = {'divIdResultOk': String}
    static targets = ['formSend', 'sendInfo', 'sendResult', 'sendError']

    connect() {
        this.divResultOk = document.getElementById(this.divIdResultOkValue)
        this.url = this.formSendTarget.getAttribute('action')
    }

    send(event) {
        event.preventDefault()
        event.stopPropagation();

        let data_form = new FormData($(this.formSendTarget)[0]);
        $(this.sendInfoTarget).removeClass('d-none');
        $(this.sendResultTarget).html("");
        this.request(data_form)
    }

    request(data_form, filter= true)
    {
        fetch(this.url, {
            method: 'POST',
            body: data_form,
        }).then(
            response => {
                if (response.ok) {
                    return response.json()
                }
                return Promise.reject(response)
            }
        ).then(data => {
            if (data.error !== undefined) {
                $(this.sendErrorTarget).html(data.error)
            }
            if (data.result !== undefined) {
                $(this.divResultOk).html(data.result);
            }
            if (data.send !== undefined) {
                $(this.sendResultTarget).html(data.send);
            }
        }).catch(error => {
            $(this.sendErrorTarget).html(error)
        }).then(always => {
            $(this.sendInfoTarget).addClass('d-none');
        })
    }
}