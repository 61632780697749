// noinspection JSUnresolvedReference
import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static values = {lang: String}

    click(event) {
        event.preventDefault()
        event.stopPropagation()
        const data = new FormData();
        data.append('change_lang', this.langValue)
        fetch('#', {
            method: 'POST',
            body: data,
        }).then(
            response => {
                if (response.ok) {
                    return response.json()
                }
                return Promise.reject(response)
            }
        ).then(data => {
            console.log(data)
            if (data.lang_ok) {
                location.reload();
            }
        })
    }
}