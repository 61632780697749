import {Controller} from "@hotwired/stimulus";

export default class extends Controller {

    show(event) {
        event.preventDefault()
        const partner = event.params['partner']
        const manager = event.params['manager']

        if (partner !== undefined) {
            if (event.currentTarget.innerHTML === '+') {
                $('.tr_' + partner).removeClass('d-none');
                event.currentTarget.innerHTML = '-';
            } else {
                $('.tr_' + partner).addClass('d-none');
                $('#btn_' + partner).html('+');
                $('.tr_m_' + partner).addClass('d-none');
                event.currentTarget.innerHTML = '+';
            }
        }

        if (manager !== undefined) {
            if (event.currentTarget.innerHTML === '+') {
                $('.tr_m_' + manager).removeClass('d-none');
                event.currentTarget.innerHTML = '-';
            } else {
                $('.tr_m_' + manager).addClass('d-none');
                event.currentTarget.innerHTML = '+';
            }
        }
    }
}