import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ['result', 'error']

    clear() {
        if (this.hasResultTarget) {
            this.resultTarget.classList.add('d-none')
            this.resultTarget.innerHTML = ''
        }
        if (this.hasErrorTarget) {
            this.errorTarget.classList.add('d-none')
            this.errorTarget.innerHTML = ''
        }
    }

}