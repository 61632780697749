import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        form: String,
        divIdResultOk: String,
        divIdResultSend: String,
        divIdResultError: String}
    static targets = ['spinner']

    connect() {
        Array.from(this.element.getElementsByTagName('a')).forEach(link => {
            //link.dataset.action = `${link.dataset.action} click->${this.identifier}#nextPage`
            link.dataset.action = `click->${this.identifier}#nextPage`
        })
        this.divIdResultOk = document.getElementById(this.divIdResultOkValue)
        this.divIdResultSend = document.getElementById(this.divIdResultSendValue)
        this.divIdResultError = document.getElementById(this.divIdResultErrorValue)
    }

    disconnect() {
        super.disconnect();
    }

    nextPage(event) {
        event.preventDefault()
        event.stopPropagation();
        const url = event.currentTarget.getAttribute('href')
        let data_form = new FormData($(this.formValue)[0]);
        $(this.spinnerTarget).removeClass('d-none');
        $(this.divIdResultSend).html("");
        fetch(url, {
            method: 'POST',
            body: data_form,
        }).then(
            response => {
                if (response.ok) {
                    return response.json()
                }
                return Promise.reject(response)
            }
        ).then(data => {
            if (data.error !== undefined) {
                $(this.divIdResultError).html(data.error)
            }
            if (data.result !== undefined) {
                $(this.divIdResultOk).html(data.result);
            }
            if (data.send !== undefined) {
                $(this.divIdResultSend).html(data.send);
            }
        }).catch(error => {
            $(this.divIdResultError).html(error)
        }).then(always => {
            $(this.spinnerTarget).addClass('d-none');
        })
    }
}