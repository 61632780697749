// noinspection JSUnresolvedReference,JSUnusedGlobalSymbols

import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static values = {patchDelete: String, patchSave: String}
    static targets = ["spinner", "error", "footer", "title", "body", "formDeletePost"]

    delete() {
        let url = null
        if (this.patchDeleteValue === "")
        {
            console.log(this.formDeletePostTarget)
            if (!this.hasFormDeletePostTarget) {
                return;
            }
            url = this.formDeletePostTarget.getAttribute('action')
        } else {
            url = this.patchDeleteValue
        }
        const form = this.bodyTarget.querySelector("[name='form-add']")
        this.sendForm(url, this.deleteOk, form)
    }

    deletePost() {
        const url = this.formDeletePostTarget.getAttribute('action')
        this.sendForm(url, this.saveOk, this.formDeletePostTarget)
    }

    sign() {
        if (this.patchSaveValue === "") return;
        const form = this.bodyTarget.querySelector("[name='form-add']")
        this.sendForm(this.patchSaveValue, this.saveOk, form)
    }

    unsign() {
        if (this.patchSaveValue === "") return;
        let url = new URL(window.location.origin + this.patchSaveValue)
        url.searchParams.append("refusign", "true")
        const form = this.bodyTarget.querySelector("[name='form-add']")
        this.sendForm(url.href, this.saveOk, form)
    }

    save() {
        if (this.patchSaveValue === "") return;
        const form = this.bodyTarget.querySelector("[name='form-add']")
        console.log(form)
        // console.log(form.get(0))
        if (!form.checkValidity()) {
            form.reportValidity()
            return false;
        }
        this.sendForm(this.patchSaveValue, this.saveOk, form)
    }

    sendForm(url, okProcess, form) {
        const that = this
        if (url === null) return
        event.preventDefault()
        event.stopPropagation()
        let data = new FormData(form)
        this.beginProcess()
        // noinspection JSUnusedLocalSymbols
        fetch(url, {
            method: 'POST',
            body: data,
        }).then(
            response => {
                if (response.ok) {
                    return response.json()
                }
                return Promise.reject(response)
            }
        ).then(data => {
            if (data.error !== undefined) {
                this.showError(data.error)
            } else {
                console.log(this)
                okProcess(that, data)
                //this.correctResult(data)
            }
        }).catch(error => {
            console.warn(error.message)
            this.showError(error.message)

        }).then(always => {
            this.endProcess()
        })
    }

    showError(message) {
        $(this.errorTarget).html(message).removeClass('d-none');
    }

    beginProcess() {
        $(this.spinnerTarget).removeClass('d-none')
        $(this.errorTarget).html("").addClass('d-none')
        $(this.footerTarget).prop("disabled", true)
    }

    endProcess() {
        $(this.footerTarget).prop("disabled", false)
        $(this.spinnerTarget).addClass('d-none')
    }

    deleteOk(that, data) {
        if (data.url !== undefined) {
            $(location).attr('href', data.url);
        } else {
            that.resultTable(data)
            that.resultClose(data)
            that.resultCall(data)
        }
    }

    saveOk(that, data) {
        if (data.url !== undefined) {
            $(location).attr('href', data.url);
        } else {
            that.resultHtml(data)
            that.resultTable(data)
            that.resultClose(data)
            that.resultCall(data)
        }
    }

    resultHtml(data) {
        if (data.html !== undefined) {
            if (data.html.add !== undefined) {
                $(data.html.add.txt).prependTo(data.html.add.id);
            }
            if (data.html.id !== undefined) {
                let dt = $(data.html.id)
                    .html(data.html.txt);
                if (data.html.id_hight !== undefined) {
                    dt = $(data.html.id_hight)
                }
                dt.toggleClass("row-highlight")
                    .fadeTo(4000, 0.85, function () {
                        $(this).toggleClass("row-highlight").fadeIn(0)
                        $(this).css('opacity', '')
                    })
            }
            if ((data.html.array !== undefined) && (typeof data.html.array === 'object')) {
                // noinspection JSUnusedLocalSymbols
                for (const [key, value] of Object.entries(data.html.array)) {
                    let dt = $(value.id)
                        .html(value.txt);
                    if (value.id_hight !== undefined) {
                        dt = $(value.id_hight)
                    }
                    dt.toggleClass("row-highlight")
                        .fadeTo(4000, 0.85, function () {
                            $(this).toggleClass("row-highlight").fadeIn(0)
                            $(this).css('opacity', '')
                        })
                }
            }
            if (data.html.body_txt !== undefined) {
                $(data.html.body_txt.id).html(data.html.body_txt.txt);
            }
            if (data.html.ids !== undefined && Array.isArray(data.html.ids)) {
                data.html.ids.forEach(function (element) {
                    $(element.id).html(element.txt)
                })
            }
            ModalW.hide();
            $('#modal-wrapper').html("");
        }
    }

    resultTable (data) {
        if (data.table !== undefined) {
            console.log('table')
            if (data.table.deleted !== undefined && Array.isArray(data.table.deleted)) {
                // видалимо з таблиці строки з id
                data.table.deleted.forEach(function (element) {
                    $(element).remove();
                })
            }
            if (data.table.prepend !== undefined) {
                // вставимо в таблицю першою строкою
                $(data.table.prepend.id).prepend(data.table.prepend.html)
            }
            if (data.table.insert !== undefined) {
                // вставимо в таблицю строки після id
                $(data.table.insert.id).after(data.table.insert.html)
            }
            if (data.table.update !== undefined) {
                // оновимо строку в таблиці з id
                console.log(data.table.update)
                $(data.table.update.id).html(data.table.update.html)
            }
            ModalW.hide();
            $('#modal-wrapper').html("");
        }
    }

    resultCall(data) {
        if (data.call !== undefined) {
            window[data.call]()
            //date.call();
        }
    }

    resultClose(data) {
        if (data.close !== undefined) {
            ModalW.hide();
            $('#modal-wrapper').html("");
        }
    }
}