// noinspection JSUnusedGlobalSymbols

import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    show(event) {
        // this.element.innerHTML = event.detail
        $(this.element).html(event.detail)
    }

    manualShow(data) {
        $(this.element).html(data)
    }
}