import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static values = {listSelector: String}

    click(event) {
        event.preventDefault()
        event.stopPropagation()
        $(this.element).blur()
        let list = $(this.listSelectorValue);
        // Попробуйте знайти лічильник списку або використовуйте довжину списку
        let counter = list.data('widget-counter') || list.children().length;
        // беремо шаблон прототипу
        let newWidget = list.attr('data-prototype');
        // змінюємо "__name__", використане в id та назві прототипу
        // числом, унікальним для ваших даних
        // фінальне ім'я атрибуту виглядає так name="contact[budget_data][2]"
        newWidget = newWidget.replace(/__name__/g, counter);
        // Збільшуємо лічильник
        counter++;
        // Та збережіть його, довжина не може бути використана, якщо дозволено видалення віджетів
        list.data('widget-counter', counter);
        // Створюємо новий елемент списку і додаємо його в список
        let newElem = $(list.attr('data-widget-tags')).html(newWidget);
        newElem.appendTo(list);
        list.find('.select2-add').select2({
            theme: "bootstrap-5",
            dropdownParent: $('#ModalW')
        });
    }
}