import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {

    static targets = ["popup", "button"]
    static values = {state: Boolean}

    connect() {
        this.hise_actions = this.element.dataset['action']
        this.element.dataset['action'] = ""
        useClickOutside(this, {eventPrefix: "header-btn"})
    }

    show(event) {
        event.preventDefault()
        if (!this.stateValue) {
            if (this.application.debug) {
                this.application.logger.log(this.identifier + ' ' + this.element.className + ': show')
            }
            this.popupTarget.classList.add('show')
            this.stateValue = true
            this.element.dataset['action'] = this.hise_actions
        } else {
            this.hide(event)
        }
    }

    hide(event) {
        if (!this.stateValue) {
            return
        }
        event.preventDefault()
        if (this.application.debug) {
            this.application.logger.log(this.identifier + ' ' + this.element.className + ': hide')
        }
        this.popupTarget.classList.remove('show')
        this.element.dataset['action'] = ""
        this.stateValue = false
    }

}