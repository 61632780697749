// noinspection JSUnusedGlobalSymbols

import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static values = {urlLoad: String, urlInfo: String}

    static targets = [
        'formSend', 'formSendSpinner', 'formSendInfo', 'formSendError',
        'formYear', 'formWeek', 'formPartner', 'formFile', 'formDataOk', 'saveInfo', 'btnSendFormStart',
        'countSpinner', 'countDiv', 'partnersShareCount',
        'salesCount', 'stocksCount', 'stocksPcsCount', 'salesPcsCount',
        'result', 'error', 'resultSpinner', 'showInfo', 'formDataDelete']

    wrapperController;

    connect() {
        $(this.formYearTarget).on('select2:select', function () {
            let event = new Event('change', {bubbles: true}) // fire a native event
            this.dispatchEvent(event);
        });
        $(this.formWeekTarget).on('select2:select', function () {
            let event = new Event('change', {bubbles: true}) // fire a native event
            this.dispatchEvent(event);
        });
        $(this.formPartnerTarget).on('select2:select', function () {
            let event = new Event('change', {bubbles: true}) // fire a native event
            this.dispatchEvent(event);
        });

        this.get_counts()
    }

    sendFormStart(event) {
        event.preventDefault()
        event.stopPropagation()
        let data = new FormData(this.formSendTarget)
        this.htmlFieldLock(true)
        fetch(this.urlLoadValue, {
            method: 'POST',
            body: data,
        }).then(response => {
            if (response.ok) {
                return response.json()
            }
            return Promise.reject(response)
        }).then(data => {
            if (data.error !== undefined) {
                this.formSendErrorTarget.innerHTML = data.error
                this.formSendErrorTarget.classList.remove('d-none')
            } else {
                this.resultTarget.innerHTML = data.table
            }
        }).catch(error => {
            this.setError(error, 'send file')
        }).then(() => {
            this.htmlFieldUnLock()
        })
    }

    // Показ таблиці з завантаженими даними
    showInfo(event) {
        event.preventDefault()
        event.stopPropagation()
        if (event.currentTarget.attributes.disabled) return
        const data = this.getDataCheckTable(event)
        this.htmlFieldLock()
        this.formSendErrorTarget.innerHTML = ""
        fetch(event.currentTarget.dataset.url, {
            method: 'POST',
            body: data,
        }).then(response => {
            if (response.ok) {
                return response.json()
            }
            return Promise.reject(response)
        }).then(data => {
            if (data.error !== undefined) {
                this.setError(data.error, '')
            } else {
                this.resultTarget.innerHTML = data.table
            }
        }).catch(error => {
            this.setError(error, 'show info')
        }).then(() => {
            this.htmlFieldUnLock()
        })

    }

    saveData(event) {
        event.preventDefault()
        event.stopPropagation()
        let data = new FormData(this.formDataOkTarget)
        this.resultTarget.innerHTML = this.saveInfoTarget.innerHTML
        this.htmlFieldLock()
        fetch(this.urlLoadValue, {
            method: 'POST',
            body: data,
        }).then(response => {
            if (response.ok) {
                return response.json()
            }
            return Promise.reject(response)
        }).then(data => {
            if (data.error === undefined) {
                this.resultTarget.innerHTML = data.text
                this.show_count_data(data)
                this.formFileTarget.value = ""
            } else {
                this.setError(data.error, '')
            }
        }).catch(error => {
            this.setError(error, 'save data')
        }).then(() => {
            this.htmlFieldUnLock()
        })
    }

    show_count_data(data) {
        // noinspection JSUnresolvedReference
        if (data.count_sales_stores === 0) {
            // noinspection JSUnresolvedReference
            this.salesCountTarget.textContent = data.count_sales
        } else {
            // noinspection JSUnresolvedReference
            this.salesCountTarget.textContent = data.count_sales + ' (' + data.count_sales_stores + ')'
        }
        // noinspection JSUnresolvedReference
        if (data.count_stocks_stores === 0) {
            // noinspection JSUnresolvedReference
            this.stocksCountTarget.textContent = data.count_stocks
        } else {
            // noinspection JSUnresolvedReference
            this.stocksCountTarget.textContent = data.count_stocks + ' (' + data.count_stocks_stores + ')'
        }
        // noinspection JSUnresolvedReference
        this.salesPcsCountTarget.textContent = data.count_sales_pcs
        // noinspection JSUnresolvedReference
        this.stocksPcsCountTarget.textContent = data.count_stocks_pcs
    }

    cancelData(event) {
        event.preventDefault()
        event.stopPropagation()
        this.resultTarget.innerHTML = ""
        this.htmlFieldUnLock()
    }

    changeSelect(event) {
        event.preventDefault()
        event.stopPropagation()
        this.get_counts()
        this.resultTarget.innerHTML = ""
    }

    deleteData(event) {
        this.checkWrapperModal()
        const data = this.getDataCheckTable()
        data.append('table', event.params.table)
        this.htmlFieldLock(false, false)
        const btn = event.currentTarget
        btn.setAttribute('disabled', '')
        // noinspection JSUnresolvedReference
        fetch(event.params.url, {
            method: 'POST',
            body: data,
        }).then(response => {
            if (response.ok) {
                return response.json()
            }
            return Promise.reject(response)
        }).then(data => this.wrapperController.manualShow(data.edit)
        ).catch(error => {
            this.setError(error, 'delete data')
        }).then(() => {
            this.htmlFieldUnLock()
            btn.removeAttribute('disabled')
        })
    }

    partnerShareEdit(event) {
        event.preventDefault()
        event.stopPropagation()
        this.checkWrapperModal()
        const data = this.getDataCheckTable()
        this.htmlFieldLock(false, false)
        const btn = event.currentTarget
        btn.setAttribute('disabled', '')
        // noinspection JSUnresolvedReference
        fetch(event.currentTarget.dataset.url, {
            method: 'POST',
            body: data,
        }).then(response => {
            if (response.ok) {
                return response.json()
            }
            return Promise.reject(response)
        }).then(data => this.wrapperController.manualShow(data.edit)
        ).catch(error => {
            this.setError(error, 'partner share edit')
        }).then(() => {
            this.htmlFieldUnLock()
            btn.removeAttribute('disabled')
        })
    }

    htmlFieldLock(send = false, clearHTML = true) {
        if (send) {
            this.formSendErrorTarget.classList.add('d-none')
            this.formSendErrorTarget.innerHTML = ""
            this.formSendSpinnerTarget.classList.remove('d-none')
            this.formSendInfoTarget.classList.remove('d-none')
        }
        if (clearHTML) {
            this.resultTarget.innerHTML = ""
        }
        this.errorTarget.classList.add('d-none')
        this.errorTarget.innerHTML = ""
        this.btnSendFormStartTarget.setAttribute('disabled', '')
        this.resultSpinnerTarget.classList.remove('d-none')
        this.showInfoTargets.forEach(element => {
            element.setAttribute("disabled", "")
        })
    }

    htmlFieldUnLock() {
        this.btnSendFormStartTarget.removeAttribute('disabled')
        this.formSendInfoTarget.classList.add('d-none')
        this.formSendSpinnerTarget.classList.add('d-none')
        this.resultSpinnerTarget.classList.add('d-none')
        this.showInfoTargets.forEach(element => {
            element.removeAttribute("disabled")
        })
    }

    get_counts() {
        const data = this.getDataCheckTable()
        this.countDivTargets.forEach(element => {
            element.classList.add('d-none');
        })
        this.countSpinnerTargets.forEach(element => {
            element.classList.remove('d-none');
        })
        this.htmlFieldLock()
        fetch(this.urlInfoValue, {
            method: 'POST',
            body: data,
        }).then(response => {
            if (response.ok) {
                return response.json()
            }
            return Promise.reject(response)
        }).then(data => {
            if (data.error === undefined) {
                this.show_count_data(data)
                // noinspection JSUnresolvedReference
                this.partnersShareCountTarget.textContent = data.partner_share
            } else {
                this.setError(data.error, '')
            }
        }).catch(error => {
            this.setError(error, 'get count')
        }).then(() => {
            this.htmlFieldUnLock()
            this.countDivTargets.forEach(element => {
                element.classList.remove('d-none');
            })
            this.countSpinnerTargets.forEach(element => {
                element.classList.add('d-none');
            })
        })
    }

    getDataCheckTable(one = false) {
        let data = new FormData()
        data.append(this.formYearTarget.dataset.info, this.formYearTarget.value)
        data.append(this.formWeekTarget.dataset.info, this.formWeekTarget.value)
        data.append(this.formPartnerTarget.dataset.info, this.formPartnerTarget.value)
        if (one !== false) {
            data.append('table', one.currentTarget.dataset.table)
        }
        return data
    }

    setError(error, name = "") {
        if (error.status !== undefined) {
            this.errorTarget.innerHTML = "Error \"" + name + "\": " + error.status
        } else {
            this.errorTarget.innerHTML = "Error \"" + name + "\": " + error
        }
        this.errorTarget.classList.remove('d-none')
    }

    checkWrapperModal() {
        if (this.wrapperController === undefined) {
            this.wrapperController = this.application.getControllerForElementAndIdentifier(
                document.getElementById('modal-wrapper'), 'modal--wrapper')
        }
    }

}