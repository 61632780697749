import {Controller} from "@hotwired/stimulus"

import '../webpush-client/webpush-client'

export default class extends Controller {
    static values = {wpkey: String, uuid: String}

    connect() {
        const options = {'uuid': this.uuidValue}

        window.WebPushClientFactory.create({
            // Public path to the service worker
            serviceWorkerPath: '/build/webpush/webpush-sw.min.js', // https://developers.google.com/web/fundamentals/push-notifications/web-push-protocol#application_server_keys
            serverKey: this.wpkeyValue, // Optionnal - your application URL to store webpush subscriptions
            subscribeUrl: '/webpush/',
        }).then(WebPushClient => {
            console.log(WebPushClient)

            const cmdResult = async response => {
                console.log(response)
                if (response !== undefined && 'data' in response && typeof response.data == 'object' && 'command' in response.data) {
                    switch (response.data.command) {
                        case 'disconnect':
                            console.log('WP disconnect')
                            WebPushClient.unsubscribe().then(cmdResult)
                            break
                        case 'connect':
                            console.log('WP connect')
                            WebPushClient.subscribe(options).then(cmdResult)
                            break
                        case 'reconnect':
                            console.log('WP reconnect 1')
                            WebPushClient.unsubscribe().then(cmdResult)
                                .then(() => {
                                    console.log('WP reconnect 2')
                                    WebPushClient.subscribe(options).then(cmdResult)
                                })
                            break
                        default:
                    }
                }
            }

            if (WebPushClient.isSupported()) {
                if (this.uuidValue === "") {
                    if (WebPushClient.getSubscription() !== null) {
                        console.log('WP start unsubscribe')
                        WebPushClient.unsubscribe().then(cmdResult)
                    }
                } else {
                    //let subscribtion = WebPushClient.getSubscription()
                    if (WebPushClient.getSubscription() === null) {
                        console.log('WP start connect')
                        WebPushClient.subscribe(options).then(cmdResult)
                    } else {
                        console.log('WP start update')
                        WebPushClient.updateOptions(options).then(cmdResult)
                    }
                }
            }

        })
    }
}