import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ['dropdownExport', 'form']

    dropdown = null

    connect() {
        this.dropdown = new bootstrap.Dropdown(this.dropdownExportTarget, {
            popperConfig: function (defaultBsPopperConfig) {
            }
        })
    }

    export(event) {
        event.preventDefault()
        event.stopPropagation()
        const format = $(event.currentTarget).data('format');
        const action = $(this.formTarget).data('action');
        $(this.formTarget).attr('action', action + "/" + format)
        $(this.formTarget).submit();
        this.dropdown.hide();
    }
}