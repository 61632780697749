import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["alert", "docType", "formAdd"]

    add(event) {
        event.preventDefault()
        event.stopPropagation()
        let data = new FormData(this.formAddTarget)
        $(this.alertTarget).html("").addClass('d-none')
        const btn = $(event.target)
        btn.prop("disabled", true)
        $('.btn-edit').prop("disabled", true)
        const url = $(this.formAddTarget).attr('action')
        fetch(url, {
            method: 'POST',
            body: data,
        }).then(
            response => {
                if (response.ok) {
                    return response.json()
                }
                return Promise.reject(response)
            }
        ).then(data => {
            if (data.error !== undefined) {
                $(this.alertTarget).removeClass('d-none').html(data.error);
            } else {
                console.log('ok')
                this.dispatch('show', {detail: data.edit});
            }
        }).catch(error => {
            $(this.alertTarget).removeClass('d-none').html(error.message);
        }).then(always => {
            //this.endProcess()
            btn.prop("disabled", false)
            $('.btn-edit').prop("disabled", false)
        })
    }

}