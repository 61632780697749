import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ["form", "result", "formUserBody", "buttonSend"]
    timeout = null

    select_photo() {
        $(this.buttonSendTarget).removeAttr('disabled')
    }

    save(event) {
        clearTimeout(this.timeout)
        event.preventDefault()
        this.clearMessage()
        $(this.buttonSendTarget).attr('disabled', 'disabled')
        fetch(this.formTarget.action, {
            method: 'POST',
            body: new FormData(this.formTarget),
        }).then(
            response => {
                if (response.ok) {
                    return response.json()
                }
                return Promise.reject(response)
            }
        ).then(data => {
            const res = $(this.resultTarget)
            if (data.result !== undefined) {
                res.html(data.result)
                res.addClass('alert-success')
                this.timeout = setTimeout( this.clearMessage.bind(this), 10000)
            }
            if (data.html !== undefined) {
                $(this.formUserBodyTarget).html(data.html)
            }
            if (data.error !== undefined) {
                res.html(data.error)
                res.addClass('alert-danger')
                this.timeout = setTimeout( this.clearMessage.bind(this), 30000)
            }
            this.dispatch('change');
        }).catch(error => {
            console.warn(error)
        })
    }

    clearMessage() {
        $(this.resultTarget).html("").removeClass('alert-danger').removeClass('alert-success')
    }
}