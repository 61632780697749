// noinspection JSUnresolvedReference

import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["inputFrom", "inputTo", "chart", "indicators", "table",
        "btnSendTxt", "btnSendTxtSend", "errorMsg", "header", "businessUnits", "productGroups"]
    static values = {url: String, msgErrDate: String, get: String}

    sendStart() {
        this.btnSendTxtTarget.classList.add('d-none')
        this.btnSendTxtSendTarget.classList.remove('d-none')
    }

    sendEnd() {
        this.btnSendTxtTarget.classList.remove('d-none')
        this.btnSendTxtSendTarget.classList.add('d-none')
    }

    hideError() {
        this.errorMsgTarget.classList.add('d-none')
    }

    showError(msg) {
        this.errorMsgTarget.innerHTML = msg
        this.errorMsgTarget.classList.remove('d-none')
    }

    requestUpdate(date_from, date_to, productGroups, businessUnits, update_url) {
        this.sendStart()

        const data = new FormData();
        data.append('date_from', date_from)
        data.append('date_to', date_to)
        if (productGroups.length > 0) {
            data.append('product_groups', productGroups)
        }
        if (businessUnits.length > 0) {
            data.append('business_units', businessUnits)
        }
        fetch(this.urlValue, {
            method: 'POST',
            body: data,
        }).then(
            response => {
                if (response.ok) {
                    return response.json()
                }
                return Promise.reject(response)
            }
        ).then(data => {
            if (data.error !== undefined) {
                this.showError(data.error)
            }
            this.headerTarget.innerHTML = data.header
            this.indicatorsTarget.innerHTML = data.indicator_view
            this.tableTarget.innerHTML = data.table_view
            if ( data.error_info.length > 0) {
                this.showError(data.error_info)
            }
            this.sendEnd()
            if (update_url) {
                let url = new URLSearchParams()
                url.set('date_from', date_from)
                url.set('date_to', date_to)
                if (businessUnits.length > 0) {
                    url.set('business_units', businessUnits)
                }
                if (productGroups.length > 0) {
                    url.set('product_groups', productGroups)
                }

                const new_url = this.getValue + '?' + url.toString()
                window.history.pushState(
                    {
                        "date_from": date_from,
                        "date_to": date_to,
                        "product_groups": productGroups,
                        "business_units": businessUnits,
                    }, "", new_url);
            }
        }).catch(error => {
            console.warn(error)
            this.showError(error.message)
            this.sendEnd()
        })
    }

    // noinspection JSUnusedGlobalSymbols
    btnSend(event) {
        event.preventDefault()
        event.stopPropagation()
        this.hideError()
        if (this.inputFromTarget.value === "" || this.inputToTarget.value === "") {
            this.showError(this.msgErrDateValue)
            return
        }
        const date_from = this.inputFromTarget.value
        const date_to = this.inputToTarget.value
        const businessUnits = Array.from(this.businessUnitsTarget.querySelectorAll("option:checked"), e => e.value)
        const productGroups = Array.from(this.productGroupsTarget.querySelectorAll("option:checked"), e => e.value)
        this.requestUpdate(date_from, date_to, productGroups, businessUnits, true)
    }

    connect() {
        //const this_ = this
        this.def_date_from = this.inputFromTarget.value
        this.def_date_to = this.inputToTarget.value
        this.def_businessUnits = Array.from(this.businessUnitsTarget.querySelectorAll("option:checked"), e => e.value)
        this.def_productGroups = Array.from(this.productGroupsTarget.querySelectorAll("option:checked"), e => e.value)
        window.onpopstate = function (e) {
            let date_from
            let date_to
            let businessUnits
            let productGroups
            if (e.state) {
                date_from = e.state.date_from
                date_to = e.state.date_to
                businessUnits = e.state.business_units
                productGroups = e.state.product_groups
            } else {
                date_from = this.def_date_from
                date_to = this.def_date_to
                businessUnits = this.def_businessUnits
                productGroups = this.def_productGroups
            }
            this.inputFromTarget.value = date_from
            this.inputToTarget.value = date_to
            for (let i = 0; i < this.businessUnitsTarget.options.length; i++) {
                const option = this.businessUnitsTarget.options[i]
                option.selected = businessUnits.indexOf(option.value) >= 0;
            }
            for (let i = 0; i < this.productGroupsTarget.options.length; i++) {
                const option = this.productGroupsTarget.options[i]
                option.selected = productGroups.indexOf(option.value) >= 0;
            }
            $(this.businessUnitsTarget).trigger('change')
            $(this.productGroupsTarget).trigger('change')
            this.requestUpdate(date_from, date_to, productGroups, businessUnits, false)

        }.bind(this);
    }
}