import {Controller} from "@hotwired/stimulus"
// import datepicker from 'bootstrap-datepicker'

export default class extends Controller {
    connect() {
        $(this.element).datepicker({
            format: "mm-yyyy",
            startView: "months",
            minViewMode: "months"
        })
    }

}