import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        $(this.element).select2({
            theme: "bootstrap-5",
            dropdownParent: $('#ModalW')
        })
        $(this.element).on('select2:select', function () {
            let event = new Event('change', {bubbles: true}) // fire a native event
            this.dispatchEvent(event);
        });
    }
}