import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ['spinner', 'info', 'error']

    show(message) {
        if (this.hasSpinnerTarget) {
            this.spinnerTarget.classList.remove('d-none')
        }
        if (this.hasInfoTarget) {
            this.infoTarget.classList.remove('d-none')
            $(this.infoTarget).html(message)
        }
        if (this.hasErrorTarget) {
            this.errorTarget.classList.add('d-none')
            this.errorTarget.innerHTML = ''
        }
    }

    hide() {
        if (this.hasSpinnerTarget) {
            this.spinnerTarget.classList.add('d-none')
        }
        if (this.hasInfoTarget) {
            this.infoTarget.classList.add('d-none')
        }
    }

    error(message) {
        if (this.hasErrorTarget) {
            this.errorTarget.classList.remove('d-none')
            this.errorTarget.innerHTML = message
        }
    }

}